import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Location: React.FC = () => {
  interface Location {
    id: number;
    name: string;
    image: string;
  }

  const [locations, setLocations] = useState<Location[]>([]);
  const apiRoute = "https://api.fresh-propertymanagement.com/public-location";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(apiRoute);
        const staticLocation = {
          id: 0,
          name: "All Blogs",
          image: "longterm_uploads/blog.webp",
        };
        setLocations([staticLocation, ...response.data]);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const handleNavigation = (id: number) => {
    console.log(id,"id");
    if (id === 0 || id == undefined ) {
      // Navigate to the static All Blogs route
      navigate(`/all-blogs`);
    } else {
      // Navigate to the category route with the location ID
      navigate(`/blogs-catgeory/${id}`);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>SELECT A LOCATION</h1>
      <div style={styles.grid}>
        {locations.map((location) => (
          <div
            style={styles.gridItem}
            key={location._id}
            onClick={() => handleNavigation(location._id)} // Use `id` instead of `_id`
          >
            <div style={styles.imageWrapper}>
              <img
                src={
                  location._id === 0
                    ? location.image
                    : `https://api.fresh-propertymanagement.com/` + location.image
                }
                alt={location.name}
                style={styles.image}
              />
              <div style={styles.overlay}>
                <h3 style={styles.name}>{location.name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "center" as const,
    fontFamily: "Arial, sans-serif",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "20px",
    color: "#333",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
  },
  gridItem: {
    position: "relative" as const,
    cursor: "pointer",
    borderRadius: "8px",
    overflow: "hidden",
    transition: "transform 0.3s ease",
  },
  imageWrapper: {
    position: "relative" as const,
    width: "100%",
    height: "100%",
  },
  image: {
    width: "100%",
    height: "200px",
    objectFit: "cover" as const,
    filter: "brightness(70%)",
    transition: "filter 0.3s ease, transform 0.3s ease",
  },
  overlay: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    transition: "opacity 0.3s ease",
  },
  name: {
    fontSize: "18px",
    color: "#fff",
    textAlign: "center" as const,
  },
};

export default Location;
